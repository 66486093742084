export default {
  namespaced: true,
  state: {},
  getters: {
    isExercito() {
      return location.hostname.includes('exercito');
    },
    isEmbedded() {
      return location.pathname.includes('/int/');
    },
    isBrokers() {
      return location.hostname.includes('brks');
    },
    isTemSaude() {
      return location.hostname.includes('temsaude');
    },
    isPHS() {
      return location.hostname.includes('habitossaudaveis');
    },
    isNamu() {
      return location.hostname.includes('namu');
    },
    isSaudeMental() {
      return location.hostname.includes('saudemental');
    },
    isLif() {
      return location.hostname.includes('lif');
    },
    isCorpoMente() {
      return location.hostname.includes('corpoemente');
    },
    isNovaSaude() {
      return location.hostname.includes('novasaude');
    },
    hasQueue(state, getters) {
      return !getters.isPHS && !getters.isExercito && !getters.isSaudeMental && !getters.isCorpoMente;
    },
    hasSchedule(state, getters) {
      return !getters.isBrokers && !getters.isLif;
    },
  },
  mutations: {},
  actions: {},
};
